import React from "react";

import { Banner } from '../index'
import { map } from "lodash";
import { Carousel, Icon } from 'antd'


export default function Introductions({
  title,
  desc,
  contacts,
  isMobile
}){
  const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <Icon type='arrow-left' />
    </div>
  )
  
  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <Icon type='arrow-right' />
    </div>
  )

  const settings = {
    dots: false,
    arrows:  true,
    infinite: true,
    speed: 600,
    autoplay: false,
    slidesToShow: isMobile ? 2 : 3,
    slidesToScroll: isMobile ? 2 : 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="about-introductions-wrapper container-wrapper">
      <Banner 
        title={title}
        desc={desc}
      >  
        <div className='logo img-wrapper'>
          <img src={require('../../static/images/logos/xunjing-light.svg')} alt='xunjing'/>
        </div>
      </Banner> 
      <div className="about-contacts-wrapper container-wrapper no-full-screen">
        <div className="about-contacts container horizontal">
          <h3 className='title'>{contacts.title}<span className='separator'>/</span></h3>
          <Carousel {...settings}>
            {map(contacts.contantList, (contact, index) => (
              <div className='contact-wrapper' key={contact.key}>
                <div className={`qr qr-${contact.key}`} />
                <div className='title'>{contact.title}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
