import React, { useState } from "react";
import { map } from "lodash";
import PeopleIcon from '../../../static/images/icons/people.svg'

import { Banner } from '../../index'
export default function Introductions({
  introductions,
  swiper,
  index,
  speed,
  showBackground
}){
  const { products } = introductions
  const [ activeProduct, setActiveProduct ] = useState(0)

  const handleChangeActiveProduct = (e) => {
    const index = e.currentTarget.getAttribute('data-id')
    setActiveProduct(parseInt(index))
  }

  const handleSlide = (e) => {
    const deviationIndex = e.currentTarget.getAttribute('data-id')
    swiper.slideTo(parseInt(deviationIndex) + index + 1, (parseInt(deviationIndex) + 1) * speed)
  }
  
  return (
    <div className="newskyline-introductions-wrapper container-wrapper">
      <div className={`parallax-bg newskyline ${showBackground ? '' : 'hidden'}`}/>
      <Banner
        logo={introductions.key}
        title={introductions.title}
        subTitle={introductions.subTitle}
        action={introductions.action}
      />   
      <div className="newskyline-products-wrapper container-wrapper no-full-screen">
        <div className="newskyline-products container horizontal">
          <div className='products-wrapper'>
            <h3 className='title'>{products.title}</h3>
            {map(products.productList, (product, index) => (
              <div 
                className='product-wrapper' 
                key={index} 
                data-id={index} 
                onMouseEnter={handleChangeActiveProduct} 
                onClick={handleSlide}
              >
                <span className='index'>0{index + 1}</span>
                {product}
              </div>
            ))}
          </div>
          <div className='images-wrapper'>
            <div className='icon'>
              <img src={PeopleIcon} alt='icon'/>
            </div>
            {map(products.productList, (product, index) => (
              <div className={`img-wrapper ${activeProduct === index ? 'active' : ''}`} key={index}>
                <img src={require(`../../../static/images/graphs/newskyline-product-${index + 1}.jpg`)} alt={product}/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
