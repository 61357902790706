import React from 'react';
import { Link } from 'react-router-dom'

const Footer = () => {

  return (
    <div className="container-wrapper">
      <div className="mask">
        <div className="mask-inner" />
      </div>
      <div className='content-wrapper'>
        <div className='contact'>   
          <div className='qr-wrapper'>
            <div className='qr qr-laioffer-consultant' />
          </div>
          <div className='title'>来Offer课程顾问</div> 
        </div>
        <div className="products">    
          <Link target="_blank" to="/home" className='img-wrapper'>
            <img src={require(`../../static/images/logos/xunjing-light.svg`)} alt='xunjing'/>
          </Link>
          <a target="_blank" className='img-wrapper' href="https://www.newskyline.net">
            <img src={require(`../../static/images/logos/newskyline-light.svg`)} alt='newskyline'/>
          </a>
          <a target="_blank" className='img-wrapper' href="https://www.laioffer.com">
            <img src={require(`../../static/images/logos/laioffer-light.svg`)} alt='laioffer'/>
          </a>
          <a target="_blank" className='img-wrapper' href="https://app.laicode.io">
            <img src={require(`../../static/images/logos/laicode.svg`)} alt='laicode'/>
          </a>
        </div>
      </div>
      <div className='copyright'>
        <p>
          版权所有 © 迅竞科技 北京迅竞科技有限责任公司<br />
          xunjingtech.com  邮箱：info@xungjingtech.com <br />
        </p>
        <p>
          <a target="_blank" href="http://www.beian.miit.gov.cn">
            京ICP备19003830号-1 
          </a>
          <span className='beian' style={{display: 'inline-block', paddingLeft: '6px'}}>
            < img src={require('../../static/images/icons/beian.png')} alt='beian' style={{verticalAlign: 'bottom', padding: '0 4px'}}/>
            京公网安备 11010102004046号
          </span> 
        </p>
      </div>
    </div>
  );
};

export default Footer
