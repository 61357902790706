import React, { useState, useEffect } from "react";
import { map } from "lodash";
import { Button } from 'antd'

export default function Programming({
  programming: {
    title,
    desc,
    action,
    features
  },
  arrived,
  isMobile
}){

    
  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if(arrived) {
      setShow(true)
    }
  }, [arrived])

  return (
    <div className="newskyline-programming-wrapper container-wrapper">
      <div className="newskyline-programming container horizontal">
        <h1 className='title'>{title}</h1>
        {isMobile || show ? (
          <React.Fragment>
            <div className={`features-wrapper ${!isMobile && show ? 'animated zoomIn slow-2 delay-05s': ''}`}>
              {map(features, (feature, index) => (
                <div className='feature-wrapper' key={feature.key}>
                  <div className={`icon icon${feature.key}`}/>
                  <div className='title'>{feature.title}</div>
                </div>
              ))}
            </div>
            <div className={`contents-wrapper ${!isMobile && show ? 'animated fadeInRightBig fast': ''}`}>
              <h1 className='title'>{title}</h1>
              <div className='desc'>{desc}</div>
              <div className='action-wrapper'>
                <Button size='large'>
                  <a href={action.to} target='_blank'>
                    {action.title}
                  </a>
                </Button>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
}
