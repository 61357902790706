import React from "react";
import { Button } from 'antd'

export default function Banner({
  logo,
  title,
  subTitle,
  desc,
  action,
  children
}){
  return (
    <div className="banner-wrapper container-wrapper no-full-screen">
      <div className='overlay'/>
      <div className="banner container">
        {logo ? (
          <div className='img-wrapper'>
            <img src={require(`../../static/images/logos/${logo}-light.svg`)} alt='logo'/>
          </div>
        ) : null}
        <h1 className='title'>{title}</h1>
        {subTitle ? <h1 className='sub-title'>{subTitle}</h1> : null }
        {desc ? <div className='desc'>{desc}</div> : null }
        {action ? (
          <div className='action-wrapper'>
            <Button size='large'>
              <a href={action.to} target='_blank'>
                {action.title}
              </a>
            </Button>
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
}
