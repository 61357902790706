import React, { useState, useEffect } from "react";
import { map } from "lodash";

export default function Introductions({
  introductions: {
    title,
    desc,
    products
  },
  swiper,
  speed,
  index,
  animateCSS,
  arrived,
  isMobile
}){
  const handleSlide = (e) => {
    const key = e.currentTarget.getAttribute('data-id')
    swiper.slideTo(index[key], (index[key] - 1) * speed)
  }
  
  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if(arrived) {
      setShow(true)
    }
  }, [arrived])

  const handleAddAnimate = (e) => {
    const node = e.currentTarget.getElementsByClassName('img-wrapper')[0]
    animateCSS(node, 'bounceIn', 3)
  }

  const handleClick = (e) => {
    const key = e.currentTarget.getAttribute('data-id')
    if(key === 'laioffer') {
      window.location.href = 'https://www.laioffer.com'
    } else {
      window.location.href = 'https://www.newskyline.net'
    }
  }

  return (
    <div className="home-introductions-wrapper container-wrapper">
      <div className="home-introductions container">
        {isMobile || show ? (
          <React.Fragment>
            <h1 className={`title ${!isMobile && show ? 'animated fadeInLeftBig fast': ''}`}>{title}</h1>
            <p className={`desc ${!isMobile && show ? 'animated fadeInLeftBig ': ''}`}>{desc}</p>
            <div className={`products-wrapper ${!isMobile && show ? 'animated fadeInLeftBig slow-1': ''}`} >
              {map(products, (product, index) => (
                <div 
                  key={index} 
                  className='product' 
                  data-id={product} 
                  //onMouseEnter={!isMobile ? handleAddAnimate : null} 
                  onClick={!isMobile ? handleSlide : handleClick}
                >
                  <div className='img-wrapper'>
                    <img src={require(`../../static/images/logos/${product}.svg`)} alt={product}/>
                  </div>
                </div>
              ))}
            </div>  
          </React.Fragment>
        ) : null} 
      </div>
    </div>
  );
}
