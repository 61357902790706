import React from "react";
import { Carousel } from "antd";
import { map } from "lodash";
import {
  Footer
} from '../index'

export default function Cooperations({
  cooperations,
  isMobile
}){
  const { title, universities, companies } = cooperations
  const settings = {
    dots: false,
    arrows:  false,
    infinite: true,
    speed: 1000,
    autoplay: true
  };

  return (
    <div className="home-cooperations-wrapper container-wrapper">
      <div className="home-cooperations container">
        <h1 className='title'>{title}</h1>
        <Carousel 
          {...settings} 
          slidesToShow={4}
          slidesToScroll={4}
          className='universities-carousel-wrapper'
        >
          {map(universities, (university, index) => (
            <div className={`img-wrapper ${university}`} key={index}>
              <img src={require(`../../static/images/logos/${university}.svg`)} alt='university'/>
            </div>
          ))}
        </Carousel>
        <Carousel 
          {...settings} 
          slidesToShow={isMobile ? 4 : 6}
          slidesToScroll={isMobile ? 4 : 6}
          className='companies-carousel-wrapper'
        >
          {map(companies, (company, index) => (
            <div className='img-wrapper' key={index}>
              <img src={require(`../../static/images/logos/${company}.png`)} alt='company'/>
            </div>
          ))}
        </Carousel>
        <Footer />
      </div>
    </div>
  );
}
