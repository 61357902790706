import React, { useState, useEffect, useMemo, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { AppComponentRoutes } from "../Routes";
import ScrollToTop  from "../ScrollToTop";
import { Header, TipModal } from './index'
import { throttle } from 'lodash'
import { Helmet } from "react-helmet";
import moment from 'moment'

require("../styles/_main.less")

function App ({
  location: {
    pathname,
    search
  },
  history
}) {
  if(search && search.match('2019diac')) {
    history.replace(search.replace(/[?=]/g, '/'))
    return null
  }

  const [ headerTheme, setHeaderTheme ] = useState('dark')
  const [ isMobile, setIsMobile ] = useState(false)
  const [ isPad, setIsPad ] = useState(false)
  const [ showTipModal, setShowTipModal ] = useState(moment().isBefore(moment("2019-11-01 00:00:00").add(1, 'months')))

  const changeHeaderTheme = (theme) => {
    setHeaderTheme(theme)
  }

  const onDocumentResize = useMemo(() => throttle(() => {
    setIsMobile(window.innerWidth < 992)
  }, 100), [])

  useEffect(() => {
    setIsMobile(window.innerWidth < 992)
    setIsPad(/ipad/i.test(navigator.userAgent))
    window.addEventListener("resize", onDocumentResize)
    return () => {
      window.removeEventListener("resize", onDocumentResize)
    }
  }, [])

  useEffect(() => {
    if(isMobile) {
      setHeaderTheme('dark')
    }
  }, [isMobile])

  const onCloseTipModal = useCallback(() => {
    setShowTipModal(false)
  }, [])

  return pathname.match('2019diac') ? (
    <AppComponentRoutes isMobile={isMobile} />
  ) : (
    <ScrollToTop>
      <TipModal visible={showTipModal} onClose ={onCloseTipModal}/>
      <Header theme={headerTheme} isMobile={isMobile}/>
      <AppComponentRoutes changeHeaderTheme={changeHeaderTheme} isMobile={isMobile} isPad={isPad}/>
    </ScrollToTop>
  )
}

const AppRouter = withRouter(App)

const Root = () => (
  <React.Fragment>
    <Helmet>
      <script>
        {`
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?809243103873756cccf6d3715236d10c";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();
        `}
      </script>
    </Helmet>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </React.Fragment>
)
 
export default Root