import React from "react";
import { Layout } from "antd";
import { FooterData } from "../../static/content";

export default function Footer() {
  const { copyright, icp, beian } = FooterData
  return (
    <Layout.Footer className="footer-wrapper">
      <p className='copyright'>{copyright}</p>
      <p className='copyright'>
        <a target="_blank" href="https://beian.miit.gov.cn/">
          {icp}
        </a><br/> 
        <span className='beian'>
          <img src={require('../../static/images/icons/beian.png')} alt='beian'/>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035158"
          >
            {beian}
          </a>
        </span>
      </p>
    </Layout.Footer>
  )
}

