import React from "react";
import { map } from "lodash";
import {
  Footer
} from '../index'
import { Icon } from 'antd'
import LocationMap from '../../static/images/graphs/map.png'

export default function Locations({
  locations
}) {
  return (
    <div className="about-locations-wrapper container-wrapper">
      <div className="about-locations container">
        <div className='map-wrapper'>
          <div className='img-wrapper'>
            <img src={LocationMap} alt='map'/>
          </div>
          
          {map(locations, (location, index) => (
            <div className={`location-wrapper ${location.key}`} key={location.key}>
              <Icon type="environment" />
              <div className='location'>
                <p className='name'>{location.name}</p>
                <p className='address'>{location.address}</p>
              </div>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </div>
  )
}

