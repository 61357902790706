export default {
  demoCourses: {
    'software-development': {
      title: "软件工程师求职趋势与面试准备公开课",
      desc: "本课程主要介绍了软件工程师求职的趋势及面试准备的方向。包括软件工程师需要掌握的5大知识和技能，最有效的学习和训练方式，一线科技公司的面试流程和评分标准。通过对二分查找这一算法的讲解，为大家演示，如何正确的学习和准备软件工程师面试。",
      lecturer: "主讲人为迅竞科技CEO孙小迅博士。他是前谷歌资深工程师、面试官，美国南加州大学计算机专业博士，高效搜索算法与无人车方向专家。他已经帮助4000+学员，进入中美一二线科技公司就职。",
    },
    'full-stack': {
      title: "全栈开发项目实践公开课",
      desc: "本课程主要介绍了如何提升全栈开发经验。包括，如何设计和实现一个工业界级别的全栈项目，什么样的全栈项目对于拿到面试机会是有帮助的。之后，通过讲解一个活动推荐系统的项目，为大家梳理全栈开发的流程和框架。",
      lecturer: "主讲人为前谷歌资深全栈工程师、面试官Vincent老师。他已经帮助上千名学员，提升全栈项目背景，拿到中美一线科技公司面试机会。",
    },
    'ai-and-data-engineering': {
      title: "人工智能与数据科学求职与面试准备公开课",
      desc: "本课程主要针对希望在人工智能与数据科学领域求职的同学，分享了数据相关岗位的求职趋势和方法。包括，人工智能和数据科学领域的职位分类，各职位的知识和技能要求，数据科学岗位面试样题分析等。并通过讲解线性回归的知识，具体剖析如何深入掌握数据科学的知识点。",
      lecturer: "主讲人为美国硅谷一线独角兽公司的资深机器学习工程师、面试官Jason老师。",
    },
    'ux-design': {
      title: "UX用户体验设计求职与面试准备公开课",
      desc: "本课程介绍了用户体验设计及其求职和面试的要求。包括，用户体验设计在科技公司的定位和职能，用户体验设计常用的软件和工具，用户体验设计主要知识模块及面试要求。通过几个具体用户体验设计案例的分析，让同学们初步感受这一岗位的工作和面试内容。",
      lecturer: "主讲人为美国知名科技公司Snap的资深产品设计师Spell老师。",
    },
    'cloud-computing': {
      title: "云计算项目实践公开课",
      desc: "本课程主要概述了云计算的前沿技术知识。包括，云计算的优势和劣势，云计算的最新发展前景，及其4大应用领域和工业界应用。同时，为大家介绍了云计算目前最流行的设计理念、框架和工具。",
      lecturer: "主讲人吕老师为清华计算机专业本科，美国罗彻斯特大学计算机博士，曾在微软研究院、谷歌等多个一线科技公司任软件工程师，现任世界级开源项目Apache Hadoop管理委员会成员。主讲人Vincent老师为前谷歌资深全栈工程师、面试官。",
    },
    'cpp-practice': {
      title: "C++工程实践公开课",
      desc: "本课程主要概述了C++在实际工业界的应用。包括，C++的优势与劣势，C++在软件工程中的主要应用领域，C++的主要语言特性等。视频还分享了设计和实现一个C++工程项目的框架和基本方法。",
      lecturer: "主讲人Leo老师为美国硅谷一线科技公司资深工程师、面试官。在C++领域有十余年的工业界工作经验。",
    },
    'product-manager': {
      title: "产品经理求职与面试准备公开课",
      desc: "本课程主要分享了产品经理的工作内容和求职方法。包括，产品经理在科技公司的定位和职能，产品经理需要的主要知识和技能，成为产品经理需要什么样的背景。同时，视频给大家分析了科技公司产品经理岗位面试的样题，帮助大家进一步了解这一岗位的求职准备。",
      lecturer: "主讲人Emma老师现任美国Uber商业智能产品的产品经理。主讲人Nathan老师现任世界石油巨头Chevron数据科学总监。",
    },
    'business-analysis': {
      title: "大数据时代商业分析的发展和趋势",
      desc: "本课程主要分享了商业分析的发展趋势和工业界应用。包括，商业分析的应用领域，商业分析的发展现状，商业分析的人才供需情况等。同时，视频深入剖析了，在财会和金融领域，商业分析的主要发力点和最新趋势。",
      lecturer: "主讲人为迅竞科技CEO孙小迅博士。他是前谷歌资深工程师、面试官，美国南加州大学计算机专业博士，高效搜索算法与无人车方向专家。",
    },
    'algorithm-interview': {
      title: "1小时击破一线科技公司面试算法难题",
      desc: "本课程通过1道科技公司经典面试难题，由浅入深的为大家分析了，在面试中应该如何拆解题目，用正确和系统的方法，向面试官阐述思路，实现代码，展现强大的问题分析和解决能力，进而通过科技公司面试。",
      lecturer: "主讲人为迅竞科技金牌老师汤老师。他是清华大学计算机本科，美国哥伦比亚大学计算机博士，在世界超一流操作系统学术会议上发表多篇论文。曾参与谷歌浏览器Chrome和谷歌云平台的研发工作。",
    },
    'data-trend': {
      title: "数据科学岗位求职趋势和面试攻略",
      desc: "本课程主要介绍了数据科学岗位的求职趋势和面试准备方法。针对想要求职数据分析师、商业分析师、数据工程师、数据科学家等岗位的同学，深入分析其所需的技能和面试准备攻略。并和大家分享了，数据科学领域求职的常见误区和解决方法。",
      lecturer: "主讲人为美国硅谷一线独角兽公司的资深机器学习工程师、面试官Jason老师。",
    }    
  },
  courses: [
    {
      key: 'software-development',
      title: '软件工程师旗舰核心课程',
      duration: '3+1个月',
      session: '80节课',
      desc: "本课程内容涵盖算法、数据结构、程序语言、面向对象设计、系统设计等，使用备受欢迎的【举一反三】训练法，50%理论+50%动手，由浅入深，帮助你全面建立系统知识体系。",
      date: "2019-12-12T19:00-08:00"
    }, 
    {
      key: 'ai-and-data-engineering',
      title: '人工智能与数据科学强化课程',
      duration: '16周',
      session: '90+节课',
      desc: "本课程结合人工智能与数据科学岗位要求，通过案例驱动的方式，为您讲解10余种常见的机器学习模型，从编程能力、模型理解、项目经验三个角度提升您的综合技术实力。课程案例包括用户黏性分析、文本聚类、Spark程序开发、深度学习等多个数据科学领域当前热门问题。",
      date: "2019-12-04T19:00-08:00"
    },
    {
      key: 'full-stack-development',
      title: "全栈开发项目实践课程",
      duration: '13周',
      session: '40节课',
      desc: "2个半月，4个覆盖全栈开发岗位的综合性项目， 涉及知识点涵盖Front-end（前端）、Back-end（后端）、 Cloud、 Data等四大开发方向，项目复杂度与跨度涵盖并超越计算机科学硕士两年的课程项目。",
      date: "2019-11-20T19:00-08:00"
    },
    {
      key: 'self-driving-car',
      title: "无人车系统课程",
      duration: '15周',
      session: '60节课',
      desc: "Want to be a software engineer working on self-driving cars? This course will provide you with core software development skills coupled with the state of the art in planning and decision making technologies for self-driving cars. ",
      date: "2019-10-15T19:00-07:00"
    },
    {
      key: 'ui-ux-design',
      duration: '12周',
      session: '30+节课',
      title: "UX工业实战课程",
      desc: "由美国交互设计师协会（IxDA）联合创始人、萨凡纳艺术设计学院交互设计专业创始人，20余年业界工作经验的UX名师等联合授课，结合UX面试标准和工业界要求，从设计理论和项目实战两方面，提升您的综合实力。 课程内容包括用户研究、交互设计、原型呈现、视觉表现，让您建立系统前沿的UX理论知识和设计思维。",
      date: "2019-10-15T19:00-07:00"
    },
    {
      key: 'cpp-in-production',
      duration: '9周',
      session: '20节课',
      title: "C++工程实践进阶班",
      desc: "通过对本课程的学习，您将能够形成软件工程师的思维能力，进一步加强编程技能，灵活运用C++语言的各种工具，找到合适而高效的方法解决实际工作中的各种问题，真正做到学以致用。",
      date: "2019-10-15T19:00-07:00"
    },
    {
      key: 'cloud-computing',
      duration: '10周',
      session: '20节课',
      title: "云计算与架构设计进阶班",
      desc: "云计算与架构设计进阶班面向有1-3年工作经验的工程师，旨在强力提升技术背景，助力职业升级和转型。进阶班拥有架构设计能力培养、前沿技术培训和资深工程师的职业辅导三大特点。",
      date: "2019-10-15T19:00-07:00"
    },
    {
      key: 'product-management',
      duration: '9周',
      session: '27节课',
      title: "产品经理核心能力进阶班",
      desc: "本课程将从“敏捷开发学习”、“产品思维培养”及“产品设计训练”三方面入手，深度结合资深数据科学家和商业分析师的职业技能辅导，对学员进行全方位的产品经理技能培养与面试培训。学员将以产品经理视角全面了解科技行业产品开发流程，深度了解面试场景、面试题型和面试技巧，并重点掌握科技领域产品类岗位的核心技能。",
      date: "2019-10-15T19:00-07:00"
    }
  ],
  instructors: [
    {
      "key": 'sun',
      "name": "孙老师 Rick Sun",
      "description": "来Offer创始人，算法培训名师，人工智能方向知名学者",
      "courses": [
        "算法",
        "数据结构"
      ],
      "academicBackground": [
        "南加州大学计算机科学博士；博士期间主攻高效人工智能和搜索算法",
        "曾在IJCAI，AAAI， AAMAS等人工智能世界著名会议发表论文数十篇"
      ],
      "experience": [
        "成功将逾3000名学生送入一线公司",
        "Google资深工程师，长期担任面试官"
      ],
      "specialization": [
        "跨系统分析平台",
        "大数据基础架构",
        "人工智能，无人车"
      ]
    },
    {
      "key": 'zhao',
      "name": "赵老师 Jing Zhao",
      "description": "来Offer联合创始人，开源社区核心成员",
      "courses": [
        "系统设计"
      ],
      "academicBackground": [
        "省理科高考状元",
        "清华大学计算机科学学士",
        "南加州大学计算机科学博士"
      ],
      "experience": [
        "Apache Hadoop等多个开源项目committer及管理委员会成员",
        "Hortonworks早期员工"
      ],
      "specialization": [
        "大数据技术",
        "分布式系统"
      ]
    },
    {
      "key": 'lu',
      "name": "吕老师 Li Lu",
      "description": "编程培训名师，分布式计算理论一线专家",
      "courses": [
        "程序设计",
        "系统设计"
      ],
      "academicBackground": [
        "清华大学计算机科学学士",
        "罗切斯特大学计算机科学博士"
      ],
      "experience": [
        "曾在微软研究院和Google担任软件工程师职位",
        "Hortonworks早期员工",
        "现任Apache Hadoop committer及管理委员会成员"
      ],
      "specialization": [
        "编程语言",
        "分布式系统"
      ]
    },
    {
      "key": 'yan',
      "name": "闫老师 Minghao Yan",
      "description": "大数据和实时系统架构师",
      "courses": [
        "算法",
        "综合实力培养"
      ],
      "academicBackground": [
        "南加州大学计算机科学硕士，年级前1%毕业"
      ],
      "experience": [
        "Google资深工程师，连续多次Top Performer",
        "长期参与技术招聘"
      ],
      "specialization": [
        "大数据基础架构，系统优化和设计",
        "跨系统实时数据分析平台"
      ]
    },
    {
      "key": 'jason',
      "name": "Jason老师",
      "description": "机器学习工程师",
      "courses": [
        "广告推荐",
        "预测模型"
      ],
      "academicBackground": [
        "加州大学戴维斯分校计算机博士",
        "清华大学本科"
      ],
      "experience": [
        "Airbnb资深机器学习工程师",
        "长期参与技术招聘"
      ],
      "specialization": [
        "广告推荐系统",
        "智能叫价"
      ]
    },
    {
      "key": 'bear',
      "name": "Bear老师",
      "description": "资深数据科学家",
      "courses": [
        "大数据金融",
        "数据收集",
        "数据可视化"
      ],
      "academicBackground": [
        "MSU计算机和工业金融数学双硕士",
        "清华大学数学学士",
      ],
      "experience": [
        "人工智能教授",
        "美国雪佛龙公司资深数据科学家"
      ],
      "specialization": [
        "数据分析和科学",
        "Fintech"
      ]
    },
  ]
}