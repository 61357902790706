import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { map } from "lodash";

import {
  Home,
  About,
  DiacHome
} from "./components/index";

const routes = [
  {
    path: "/home",
    exact: true,
    component: Home
  },
  {
    path: "/about",
    exact: true,
    component: About
  },
  {
    path: "/2019diac/:name",
    exact: true,
    component: DiacHome
  },
];

const animateCSS = (node, animationName, duration = 2, delay = 0, callback) => {
  node.classList.add('animated', animationName, `${duration}s`, `delay-${delay}s`)

  function handleAnimationEnd() {
      node.classList.remove('animated', animationName)
      node.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') callback()
  }

  node.addEventListener('animationend', handleAnimationEnd)
}

const BaiduStat = props => {
  let children = props.children;
  window._hmt = window._hmt || [];
  window._hmt.push(["_trackPageview", props.location.pathname]);
  return children;
};

export const AppComponentRoutes = (props) => (
  <Switch>
    {map(routes, route => (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        render={routeProps => (
          <BaiduStat {...routeProps}>
            <route.component 
              {...routeProps}
              animateCSS = {animateCSS} 
              {...props} 
            />
          </BaiduStat>
        )}
      />
    ))}
    <Redirect from="/" to="/home" />
  </Switch>
)