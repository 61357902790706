export const submitUserInfo = values => {
  return fetch("https://hooks.zapier.com/hooks/catch/2735348/exbmpg/", {
    method: "POST",
    body: JSON.stringify({
      firstName: values.name,
      phone: values.phone,
      wechat: values.wechat,
      message: `id: ${values.id}, course: ${values.course}, time: ${new Date()}`
    })
  });
};
