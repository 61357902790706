import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'react-router-dom'
import { Layout, Popover, Icon } from "antd";

export default function Header({
  isMobile
}) {
  const [ mobileMenuCollapsed, setMobileMenuCollapsed ] = useState(false)

  useEffect(() => {
    setMobileMenuCollapsed(false)
  }, [isMobile])

  const toggleMobileMenu = () => {
    setMobileMenuCollapsed(!mobileMenuCollapsed)
  }

  const renderMenu = (
    <React.Fragment>
      <ScrollLink activeClass="active" to="video" spy={true} smooth={true} duration={500} offset={0}>
        试听课
      </ScrollLink>
      <ScrollLink activeClass="active" to="instructors" spy={true} smooth={true} duration={500} offset={-50}>
        师资介绍
      </ScrollLink>
      <ScrollLink activeClass="active" to="courses" spy={true} smooth={true} duration={500} offset={-50}>
        课程介绍
      </ScrollLink>
      <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-50}>
        联系我们
      </ScrollLink>
    </React.Fragment>
  )

  return (
    <Layout.Header className='video-header-wrapper'>
      <div className="header">
        <Link target="_blank" to="/home" className='logo img-wrapper' />
        <div className='header-menu'> 
          {renderMenu}
        </div>
        <Popover
          className="mobile-header-menu"
          trigger="click"
          content={renderMenu}
          placement="bottomRight"
          visible={mobileMenuCollapsed}
          onVisibleChange={toggleMobileMenu}
          getPopupContainer={() => document.getElementsByClassName('mobile-header-menu')[0]}
        >
          <Icon type="bars" />
        </Popover>
      </div>
    </Layout.Header>
  )
}
