export default {
  title: "领跑科技教育时代，培育具有“全球胜任力和领袖力”的未来科技人才",
  desc: "团队成员由具有多年硅谷大公司及创业公司经验的资深软件工程师组成，所有授课和实训讲师均从国外CS名校毕业，并有丰富的国内外企业项目和教学经验",
  contacts: {
    title: '联系我们',
    contantList: [
      {
        key: 'children-consultant',
        title: '少儿编程课程顾问Coco'
      },
      {
        key: 'newskyline-consultant',
        title: '海外留学课程顾问Sandi'
      },
      {
        key: 'newskyline',
        title: '新地平线公众号'
      },
      {
        key: 'laioffer',
        title: '来offer公众号'
      },
      {
        key: 'laioffer-consultant',
        title: '来Offer课程顾问'
      }
    ]
  },
  jobs: {
    title: '加入我们',
    location: '工作城市',
    position: '在招职位',
    hr: {
      title: '联系HR',
      modal: {
        title: '联系我们',
        subTitle: '请您使用微信扫描下方二维码添加微信',
        key: 'hr',
        desc: ['（HR微信号）', '联系人：宫女士', '邮箱：hr@xunjingtech.com']
      }
    },
    welfares: [
      {
        key: '1',
        title: '五险一金',
        icon: 'heart'
      },
      {
        key: '2',
        title: '定期体检',
        icon: 'fork'
      },
      {
        key: '3',
        title: '年终奖',
        icon: 'money-collect'
      },
      {
        key: '4',
        title: '带薪年假',
        icon: 'picture'
      },
      {
        key: '5',
        title: '零食下午茶',
        icon: 'coffee'
      }
    ],
    jobList: [
      {
        location: ['北京', '深圳', '成都'],
        position: '课程顾问',
        informations: [
          {
            title: '岗位职责',
            desc: [
              '为意向用户邀约试听课，介绍产品，解答疑问',
              '利用电话和线上的方式，维护现有用户，开拓新用户，促成课程订单',
              '对未成单客户进行定期的回访与跟进，挖掘客户需求',
              '协助家长办理签约及缴费手续'
            ]
          },
          {
            title: '职位要求',
            desc: [
              '本科及以上学历，性格外向',
              '表达能力优秀，学习能力强，能很快系统了解产品'
            ]
          }
        ]
      },
      {
        location: ['深圳'],
        position: '活动与社群运营',
        informations: [
          {
            title: '岗位职责',
            desc: [
              '负责微信社群用户活跃、留存、转化目标；依据用户需求、制定运营方案或运营机制',
              '根据公司运营整体策略，通过运营推广提高微信及社群流量，达到公司运营推广目标',
              '负责线上社群运营，有良好的用户运营方法论，了解通过社群、活动等方式对用户进行运营',
              '负责建立良好的用户沟通及社群管理规则，挖掘潜力及核心用户，促成用户转化'
            ]
          },
          {
            title: '职位要求',
            desc: [
              '本科及以上学历，自学能力强，执行力强',
              '熟悉市场上主流社交产品，对内容具备高敏感度和判断能力',
              '有微信群运营经验者优先',
              '逻辑性强，有独立完成工作能力和较强的团队协作能力'
            ]
          }
        ]
      },
      {
        location: ['深圳'],
        position: '海报/平面设计',
        informations: [
          {
            title: '岗位职责',
            desc: [
              '负责公司市场推广所需海报等宣传品设计',
              '参与公司产品、网站、微信文案、视频、宣传册等的策划、设计、编辑',
              '关注并掌握互联网新媒体营销的变化，及时创新',
              '负责其他设计相关工作'
            ]
          },
          {
            title: '职位要求',
            desc: [
              '本科以上学历，美术、平面设计、广告设计其他相关专业者优先',
              '精通Photoshop、Illustrator、Adobe Premiere等相关设计软件',
              '手绘及电脑绘图能力优秀，可独立完成插画和动图绘制',
              '熟悉微信平台，擅长H5营销，创意能力强，能独立完成设计项目',
              '良好的沟通、协调、交流能力，有责任心，具有团队协作精神',
              '应聘时请务必提供个人作品'
            ]
          }
        ]
      }
    ]
  },
  locations: [
    {
      key: 'american',
      name: '美国',
      address: '2350 mission college Blvd, Santa Clara Ca 95054'
    },
    {
      key: 'beijing',
      name: '北京',
      address: '北京市海淀区丹棱街6号1幢7层818'
    },
    {
      key: 'chengdu',
      name: '成都',
      address: '成都市武侯区世外桃源广场A2013'
    },
  ],
};
