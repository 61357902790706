import Loadable from "react-loadable";
import LoadingMask from "./common/LoadingMask";

export { default as Header } from "./common/Header";
export { default as Footer } from "./common/Footer";
export { default as Banner } from "./common/Banner";
export { default as TipModal } from "./common/TipModal";

export { default as HomeBanner } from "./home/Banner";
export { default as Introductions } from "./home/Introductions";
export { default as Cooperations } from "./home/Cooperations";

export { default as LaiofferIntroductions } from "./home/laioffer/Introductions";
export { default as LaiofferCourses } from "./home/laioffer/Courses";

export { default as NewskylineIntroductions } from "./home/newskyline/Introductions";
export { default as NewskylineProgramming } from "./home/newskyline/Programming";
export { default as NewskylineCourse } from "./home/newskyline/Course";
export { default as NewskylineTour } from "./home/newskyline/Tour";

export { default as AboutContacts } from "./about/Contacts";
export { default as AboutJobs } from "./about/Jobs";
export { default as AboutLocations } from "./about/Locations";

export { default as CountDownTimer } from "./diac/CountDownTimer";
export { default as DiacHeader } from "./diac/Header";
export { default as DiacCourses } from "./diac/Courses";
export { default as DiacVideo } from "./diac/Video";
export { default as DiacInstructors } from "./diac/Instructors";
export { default as DiacFooter } from "./diac/Footer";


export const Home = Loadable({
  loader: () => import("./home/Home"),
  loading: LoadingMask
});

export const About = Loadable({
  loader: () => import("./about/About"),
  loading: LoadingMask
});

export const DiacHome = Loadable({
  loader: () => import("./diac/Home"),
  loading: LoadingMask
});

