import React, { useEffect, useCallback, useRef } from "react";
import { Modal } from "antd";

function TipModal({
  visible,
  onClose
}) {
  const timeout = useRef(null)

  const onCloseModal = useCallback(() => {
    clearTimeout(timeout.current)
    onClose()
  }, [])

  useEffect(() => {
    timeout.current = setTimeout(onCloseModal, 6000)
  }, [onCloseModal])
  
  return (
    <Modal
      visible={visible}
      onCancel={onCloseModal}
      footer={null}
      width={'80%'}
      centered={true}
      wrapClassName='tip-modal-wrapper'
      maskStyle={{backgroundColor: 'transparent'}}
    >
      <a href='https://www.biendata.com/competition/2019diac/?source=xunjing' target='_blank'>
        <div className='img-wrapper logo'>
          <img src={require('../../static/images/graphs/tip-modal-logo.png')}/>
        </div>
        <div className='img-wrapper title'>
          <img src={require('../../static/images/graphs/tip-modal-title.png')}/>
        </div>
        <div className='img-wrapper sub-title'>
          <img src={require('../../static/images/graphs/tip-modal-sub-title.png')}/>
        </div>
        <div className='img-wrapper unit'>
          <img src={require('../../static/images/graphs/tip-modal-unit.png')}/>
        </div>
        <div className='img-wrapper operations'>
          <img src={require('../../static/images/graphs/tip-modal-operations.png')}/>
        </div>
      </a>
    </Modal>
  )
}

export default TipModal
