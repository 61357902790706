import React from 'react';
import Countdown from 'react-countdown-now';

const CountDownTimer = ({ startTime, onComplete }) => {
  const renderer = ({ days, hours, minutes, seconds }) => (
    <span className="timer">
      {days > 0 && (
        <React.Fragment>
          <em className="number">{days}</em>
          <span>天</span>
        </React.Fragment>
      )}
      <em className="number">{hours}</em>
      <span>小时</span>
      <em className="number">{minutes}</em>
      <span>分</span>
      <em className="number">{seconds}</em>
      <span>秒</span>
    </span>
  )

  return (
    <Countdown
      date={new Date(startTime)}
      renderer={renderer}
      onComplete={onComplete}
    />
  );
};

export default CountDownTimer;
