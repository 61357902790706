import React, { useState, useEffect, useMemo } from "react";
import { map, filter, reduce,  indexOf, concat, uniq, find } from "lodash";
import { Radio, Button, Modal, Dropdown, Menu, Icon } from 'antd'
import JobsImg from '../../static/images/graphs/about-jobs.jpg'
import WelfaresImg from '../../static/images/graphs/about-welfares.jpg'

export default function Jobs({
  jobs: {
    title,
    location,
    position,
    hr,
    welfares,
    jobList
  },
  isMobile,
  swiper
}){
  const [ jobs, setJobs ] = useState(null)
  const [ locationOptions, setLocationOptions ] = useState([])
  const  positionAllOptions = useMemo(() => uniq(
    reduce(jobList, function(result, n) {
      return concat(result, [ n.position ])
    }, [])
  ), [])
  const [ positionOptions, setPositionOptions ] = useState([])
  const [ selectedLocation, setSelectedLocation ] = useState('')
  const [ selectedPosition, setSelectedPosition ] = useState('')
  const [ hrModalVisible, setHrModalVisible ] = useState(false)

  useEffect(() => {
    setLocationOptions(uniq(reduce(jobList, function(result, n) {
      return concat(result, n.location)
    }, [])))
  }, [])

  const handleChangeLocation = (e) => {
    const value = e.key || e.target.value
    setSelectedLocation(value)
    const newJobs = filter(jobList, function(job) { 
      return indexOf(job.location, value) !== -1 
    })
    const newPositionOptions = uniq(reduce(newJobs, function(result, n) {
      return concat(result, [ n.position ])
    }, []))
    setPositionOptions(newPositionOptions)
    setSelectedPosition('')
  }

  const handleChangePosition = (e) => {
    const value = e.key || e.target.value
    setSelectedPosition(value)
    const newJobs = find(jobList, function(job) { 
      return job.position === value && indexOf(job.location, selectedLocation) !== -1 ; 
    })
    setJobs(newJobs)
  }

  const showModal = () => {
    setHrModalVisible(true)
  }

  const closeModal = () => {
    setHrModalVisible(false)
  }

  const handleMouseOver = () => {
    swiper.mousewheel.disable()
  }

  const handleMouseOut = () => {
    swiper.mousewheel.enable()
  }
  
  return (
    <div className="about-jobs-wrapper container-wrapper">
      <div className="about-jobs container">
        <div className='form-wrapper'>
          <h3 className='title'>{title}<span className='separator'>/</span></h3>
          <div className='select-wrapper'> 
            <Dropdown
              overlay = {(
                <Menu onClick={handleChangeLocation}>
                  {map(locationOptions, (option, index) => (
                    <Menu.Item key={option} className={`${option === selectedLocation ? 'selected' : ''}`}>{option}</Menu.Item>
                  ))}
                </Menu>
              )}
              overlayClassName = 'select-dropdown'
              getPopupContainer={() => document.getElementsByClassName('select-wrapper')[0]}
            >
              <div className='select'>
                {selectedLocation || location}
                <Icon type="down" className='select-arrow' />
              </div>
            </Dropdown>
            <Dropdown
              overlay = {(
                <Menu onClick={handleChangePosition}>
                  {map(positionOptions, (option, index) => (
                    <Menu.Item key={option} className={`${option === selectedPosition ? 'selected' : ''}`}>{option}</Menu.Item>
                  ))}
                </Menu>
              )}
              disabled = {selectedLocation ? false : true}
              overlayClassName = 'select-dropdown'
              getPopupContainer={() => document.getElementsByClassName('select-wrapper')[0]}
            >
              <div className='select'>
                {selectedPosition || position}
                <Icon type="down" className='select-arrow'/>
              </div>
            </Dropdown>
          </div>
          <div className='mobile-select-wrapper'> 
            <div className='title'>{location}</div>
            <Radio.Group 
              buttonStyle="outline" 
              value={selectedLocation} 
              onChange={handleChangeLocation}
              className = 'select-radio-group location'
            >
              {map(locationOptions, (option, index) => (
                <Radio.Button key={option} value={option}>{option}</Radio.Button>
              ))}
            </Radio.Group>
            <div className='title'>{position}</div>
            <Radio.Group 
              buttonStyle="outline" 
              value={selectedPosition} 
              onChange={handleChangePosition}
              className = 'select-radio-group position'
            >
              {map(positionAllOptions, (option, index) => (
                <Radio.Button 
                  key={option} 
                  value={option} 
                  disabled={ selectedLocation && indexOf(positionOptions, option) !== -1 ? false : true}
                >{option}</Radio.Button>
              ))}
            </Radio.Group>
          </div>
          <Button onClick={showModal}>{hr.title}</Button>
        </div>
        <div className='content-wrapper'>
          {jobs && Object.keys(jobs).length ? (
            <React.Fragment>
              <div className='img-wrapper'>
                <img src={JobsImg} alt='job'/>
              </div>
              <div className='informations-wrapper' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {map(jobs.informations, (information, index) => (
                  <React.Fragment key={index}>
                    <div className='title'>{information.title}</div>
                    {map(information.desc, (desc, index) => (
                      <div className='desc' title={desc} key={index}>{index + 1}. {
                        //!isMobile && desc.length > 32 ? desc.slice(0, 30) + '...' : desc
                        desc
                      }</div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='img-wrapper'>
                <img src={WelfaresImg} alt='welfare'/>
              </div>
              <div className='welfares-wrapper'>
                {map(welfares, (welfare, index) => (
                  <div className='welfare-wrapper' key={welfare.key}>
                    <Icon type={welfare.icon} />
                    <div className='index'>0{index + 1}</div>
                    <div className='title'>{welfare.title}</div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <Modal
        title={hr.modal.title}
        visible={hrModalVisible}
        footer={null}
        onCancel={closeModal}
        wrapClassName = 'hr-modal-wrapper'
        centered={true}
      >
        <p>{hr.modal.subTitle}</p>
        <div className='qr qr-hr' />
        {map(hr.modal.desc, (desc, index) => (
          <p key={index}>{desc}</p>
        ))}
      </Modal>
    </div>
  );
}
