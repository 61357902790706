export default {
  title: "传播世界领先科技",
  subTitle: "帮助学员迅速提升核心竞争力",
  introductions: {
    title: "迅竞科技",
    desc:
      "迅竞科技是极具实力的高科技在线教育和实训平台，公司成立于2013年，旗下包括“来Offer”， “新地平线New Skyline”等品牌，覆盖计算机科学方向从K12到职业能力提升的完整方案。",
    products: ["laioffer", "newskyline"]
  },
  products: {
    laioffer: {
      introductions: {
        key: "laioffer",
        title: "做硅谷的IT黄埔军校",
        subTitle:
          "提供高水平的IT培训课程和就业指导，帮助学员进军硅谷一二线科技公司",
        action: {
          title: "立即前往",
          to: "https://www.laioffer.com"
        },
        introductionList: [
          {
            title: "6年",
            desc: "成立于2013年"
          },
          {
            title: "10-25",
            desc: "每周Offer数"
          },
          {
            title: "80%+",
            desc: "就业率"
          },
          {
            title: "3000+",
            desc: "一线公司offer"
          },
          {
            title: "$135K",
            desc: "平均薪资"
          }
        ]
      },
      services: {
        title: "服务内容",
        serviceList: [
          {
            key: 1,
            title: "24小时答疑"
          },
          {
            key: 2,
            title: "简历修改"
          },
          {
            key: 3,
            title: "模拟面试"
          },
          {
            key: 4,
            title: "内部推荐"
          }
        ]
      },
      courses: {
        title: "核心课程",
        action: "了解课程",
        courseList: [
          {
            key: 0,
            title: "软件工程师旗舰核心课程",
            desc:
              "课程内容涵盖算法、数据结构、程序语言、面向对象设计、系统设计等。理论与实践并重：教学形式分为课堂讲解与上机课。在上机课上，您将在一线科技公司任职的教学助理（TA）的帮助下，在真实的应用环境中，实际提高编程水平。",
            action: "https://www.laioffer.com/zh/course/software-development/"
          },
          {
            key: 1,
            title: "全栈开发项目实践课程",
            desc:
              "2个半月，4个覆盖全栈开发岗位的综合性项目， 涉及知识点涵盖Front-end（前端）、Back-end（后端）、 Cloud、 Data等四大开发方向，项目复杂度与跨度涵盖并超越计算机科学硕士两年的课程项目。",
            action: "https://www.laioffer.com/zh/course/full-stack-development/"
          },
          {
            key: 2,
            title: "人工智能与数据科学强化课程",
            desc:
              "结合人工智能与数据科学岗位要求，通过案例驱动的方式，为您讲解10余种常见的机器学习模型，从编程能力、模型理解、项目经验三个角度提升您的综合技术实力。课程案例包括用户黏性分析、文本聚类、Spark程序开发、深度学习等多个数据科学领域当前热门问题。",
            action:
              "https://www.laioffer.com/zh/course/ai-and-data-engineering/"
          },
          {
            key: 3,
            title: "无人车系统课程",
            desc:
              "Want to be a software engineer working on self-driving cars? This course will provide you with core software development skills coupled with the state of the art in planning and decision making technologies for self-driving cars. ",
            action: "https://www.laioffer.com/zh/course/self-driving-car/"
          },
          {
            key: 4,
            title: "UX工业实战课程",
            desc:
              "由美国交互设计师协会（IxDA）联合创始人、萨凡纳艺术设计学院交互设计专业创始人，20余年业界工作经验的UX名师等联合授课，结合UX面试标准和工业界要求，从设计理论和项目实战两方面，提升您的综合实力。 课程内容包括用户研究、交互设计、原型呈现、视觉表现，让您建立系统前沿的UX理论知识和设计思维。",
            action: "https://www.laioffer.com/zh/course/ui-ux-design/"
          }
        ]
      }
    },
    newskyline: {
      introductions: {
        key: "newskyline",
        title: "诞生于清华  精炼于硅谷!",
        subTitle:
          "领跑科技教育时代，为中国6-18岁青少年构建面向未来的思维方式和科技素养",
        action: {
          title: "立即前往",
          to: "https://www.newskyline.net"
        },
        products: {
          title: "关于新地平线的介绍",
          productList: ["青少年编程", "留学背景提升课程", "硅谷实训"]
        }
      },
      products: {
        programming: {
          key: "programming",
          title: "青少年编程",
          desc:
            "采用清华大学计算机人才培养体系与硅谷前沿科技教育方法的青少年编程课程。教师团队中有ACM金牌获得者、NOI国家队教练、清华大学计算机教材的作者以及硅谷名企的高级工程师。",
          action: {
            title: "立即前往",
            to: "https://www.newskyline.net/children-programming"
          },
          features: [
            {
              key: 2,
              title: "线下实体授课"
            },
            {
              key: 3,
              title: "线上直播教学"
            },
            {
              key: 4,
              title: "清华+硅谷强大师资"
            }
          ]
        },
        course: {
          key: "course",
          title: "留学背景提升课程",
          features: [
            {
              key: 2,
              title: "PBL项目制学习"
            },
            {
              key: 3,
              title: "名校教授+华尔街高管+名企工程师联袂授课"
            },
            {
              key: 4,
              title: "获得高含金量结业证书和推荐信"
            }
          ],
          categories: [
            {
              key: "ba",
              titleEn: "Business Analytics",
              title: "商业分析",
              desc:
                "商业分析Business Analytics（简称BA），是一个交叉学科，是综合应用数学、统计、计算机和各种商业定律的新兴专业。",
              action: {
                title: "了解课程",
                to: "https://www.newskyline.net/course/commerce"
              }
            },
            {
              key: "cs",
              titleEn: "Computer Science",
              title: "计算机科学",
              desc:
                "计算机科学Computer Science（简称CS）是系统性研究信息与计算的理论基础以及它们在计算机系统中如何实现与应用的实用技术学科。",
              action: {
                title: "了解课程",
                to: "https://www.newskyline.net/course/science"
              }
            }
          ]
        },
        tour: {
          key: "tour",
          title: "硅谷实训",
          desc:
            "培育具有“全球胜任力和领袖力”的未来科技人才，面向广大国内学子研发的高端学游产品，包含三个年龄段：初高中（青少年），大学以及MBA。",
          features: [
            {
              key: 1,
              title: "国际化视野培养及思维训练"
            },
            {
              key: 2,
              title: "了解前沿科技趋势及发展"
            },
            {
              key: 3,
              title: "实地感受名校名企环境和氛围"
            }
          ]
        }
      }
    }
  },
  cooperations: {
    title: "合作机构",
    universities: [
      "stanford",
      "yale",
      "columbia",
      "carnegie",
      "njlg",
      "scdx",
      "peking"
    ],
    companies: ["alibaba", "jingdong", "wangyi", "sogou", "tinder", "xiaomi"]
  }
};
