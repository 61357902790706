import React, { useState, useMemo, useCallback } from 'react';
import { Carousel, Row, Col, Button, Icon } from 'antd';
import { map } from 'lodash';
import { CountDownTimer } from '../index';

function NextArrow({ className, style, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <Icon type="arrow-right" />
    </div>
  );
}

function PrewArrow({ className, style, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <Icon type="arrow-left" />
    </div>
  );
}

export default function Courses({
  courses
}) {
  const [ activeIndex, setActiveIndex ] = useState(0)
  const [ currTime, setCurrTime ] = useState(Date.now())

  const onCompleteCountDownTimer = useCallback(() => {
    setCurrTime(Date.now())
  }, [])

  const afterChange = index => {
    setActiveIndex(index)
  };

  const settings = useMemo(() => ({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: afterChange,
    nextArrow: <NextArrow />,
    prevArrow: <PrewArrow />,
    centerMode: true
  }), [])

  return (
    <div className="container-wrapper">
      <h2 className="main-title">
        王牌课程
        <div className="dash" />
      </h2>
      <Carousel {...settings}>
        {map(courses, (course, index) => (
          <Row type="flex" className="course-card" key={index}>
            <Col xs={24} lg={10} className="image-container">
              <img
                src={require(`../../static/images/graphs/diac/${course.key}.jpg`)}
                alt={course.key}
              />
            </Col>
            <Col xs={24} lg={14} className="content">
              <h2>{course.title}</h2>
              <p className='desc' title={course.desc}>{course.desc}</p>
              <p className="class-hour">
                {course.duration} | {course.session}
              </p>
              <div className="course-time">
              {currTime > new Date(course.date) ? "即将开课"
                : (
                <React.Fragment>
                  距离开课还有
                  <CountDownTimer
                    startTime={course.date}
                    onComplete={onCompleteCountDownTimer}
                  />
                </React.Fragment>
              )}
              </div>
              {activeIndex === index ? (
                <a
                  className="ant-btn ant-btn-primary"
                  target="_blank"
                  href={`https://www.laioffer.com/zh/course/${course.key}`}
                >
                  课程咨询
                </a>
              ) : (
                <Button type="primary">课程咨询</Button>
              )}
            </Col>
          </Row>
        ))}
      </Carousel>
    </div>
  )
}
