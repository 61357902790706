import React, { useState, useRef, useEffect } from "react";
import { map } from "lodash";
import { Carousel, Button, Tabs, Icon } from 'antd'

const { TabPane } = Tabs;

export default function Introductions({
  courses : {
    title,
    action,
    courseList
  },
  showBackground,
  arrived,
  isMobile
}){
  const [ activeIndex, setActiveIndex ] = useState(0)

  const sliderRef = useRef()

  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if(arrived) {
      setShow(true)
    }
  }, [arrived])


  const PrevArrow = ({ className, style }) => (
    <div
      className={className}
      style={{ ...style}}
      onClick={() => {
        const newIndex = (activeIndex - 1 + courseList.length) % courseList.length
        sliderRef.current.goTo(newIndex)
        setActiveIndex(newIndex)
      }}
    >
      <Icon type="left" />
    </div>
  )
  
  const NextArrow = ({ className, style }) => (
    <div
      className={className}
      style={{ ...style}}
      onClick={() => {
        const newIndex = (activeIndex + 1 + courseList.length) % courseList.length
        sliderRef.current.goTo(newIndex)
        setActiveIndex(newIndex)
      }}
    >
      <Icon type="right" />
    </div>
  )
  
  const settings = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  return (
    <div className="laioffer-courses-wrapper container-wrapper">
      <div className={`parallax-bg laioffer ${showBackground ? '' : 'hidden'}`}/>
        <div className="laioffer-courses container">
        {isMobile || show ? (
          <React.Fragment>
            <div className={`tabs-wrapper ${!isMobile && show ? 'animated slideInRight': ''}`} >
              <div className='line' />
              <div className='tabs'>      
                <div className='title'>{title}</div>
                <Tabs activeKey={activeIndex + ''}>
                  {map(courseList, (course, index) => (
                    <TabPane tab={<span>0{course.key + 1}</span>} key={course.key} />
                  ))}
                </Tabs>
              </div>
            </div>
            <Carousel {...settings} ref={sliderRef} 
              className={`courses-wrapper ${!isMobile && show ? 'animated fadeInLeft delay-1s': ''}`}
            >
              {map(courseList, (course, index) => (
                <div className='course-wrapper' key={course.key}>
                  <div className='content'>
                    <div className='title'>{course.title}</div>
                    <div className='desc'>{course.desc}</div>
                    <Button className='action' size='large'><a href={course.action} target='_blank'>{action}</a></Button>
                  </div>
                  <div className='imgs-wrapper'>
                    <div className='img-wrapper active'>
                      <img src={require(`../../../static/images/graphs/laioffer-course-${course.key + 1}.jpg`)} alt='course'/>
                    </div>
                    <div className='img-wrapper no-active'>
                      <img src={require(`../../../static/images/graphs/laioffer-course-${(course.key + 1) % courseList.length + 1}.jpg`)} alt='course'/>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </React.Fragment>
        ) : null}  
        <div className='mobile-courses-wrapper'>
          {map(courseList, (course, index) => (
            <div className='course-wrapper' key={course.key}>
              <div className='content'>
                <div className='title'>{course.title}</div>
                <div className='desc'>{course.desc}</div>
                <Button className='action' size='large'><a href={course.action} target='_blank'>{action}</a></Button>
              </div>
              <div className='imgs-wrapper'>
                <div className='img-wrapper active'>
                  <img src={require(`../../../static/images/graphs/laioffer-course-${course.key + 1}.jpg`)}/>
                </div>
                <div className='img-wrapper no-active'>
                  <img src={require(`../../../static/images/graphs/laioffer-course-${(course.key + 1) % courseList.length + 1}.jpg`)}/>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
