import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal, Form, Button, Input, notification, message } from 'antd'
import { throttle, upperCase } from 'lodash'
import { submitUserInfo } from "../../actions";
import Certification from "./Certification"
import { saveSvgAsPng } from 'save-svg-as-png'
import md5 from 'js-md5'

let timer = null

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function FormInner({
  form: {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    validateFields
  },
  courseKey,
  course,
  handleCloseModal
}) {
  const nameError = isFieldTouched("name") && getFieldError("name");
  const phoneError = isFieldTouched("phone") && getFieldError("phone");
  const wechatError = isFieldTouched("wechat") && getFieldError("wechat");
  const [ certificationValues, setCertificationValues ] = useState(null)

  const [ hasSubmit, setHasSubmit ] = useState(false)

  useEffect(() => {
    validateFields(() => {});
  }, [])

  const getCertificationId = ({name, phone}) => {
    let md5Value = md5(name + phone + courseKey)
    return upperCase(md5Value.replace(/\d/g, '').substr(0, 2)) + md5Value.substr(2, 18)
  }

  const throttleSubmit = useMemo(() => throttle((e) => {
    validateFields((err, values) => {
      if (!err) {
        const id = getCertificationId(values)
        submitUserInfo({
          ...values,
          course: courseKey,
          id: id
        })
          .then(response => {
            if (response.status === 200) {
              notification.success({
                message: "谢谢, " + values.name,
                description: "信息提交成功， 请下载试听证书！",
                duration: 10
              });
              const date = new Date().toDateString().split(' ')
              setCertificationValues({
                name: values.name,
                date: `${date[1]} ${date[2]}, ${date[3]}`,
                id: id
              })
              setHasSubmit(true)
            } else {
              throw new Error("network error");
            }
          })
          .catch((error) => {
            notification.error({
              message: "联系失败",
              description:
                "抱歉，我们暂时无法接收您的信息。 请扫描课程顾问二维码进行咨询。",
              duration: 10
            });
          });
      }
    });
  }, 2000), [])

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    throttleSubmit();
  }, [throttleSubmit]);

  const downloadCertification = useCallback(() => {
    throttleDownloadCertification(certificationValues)
  }, [certificationValues])

  const throttleDownloadCertification = useMemo(() => throttle((values) => {
    message.success('正在下载试听证书，请稍后', 5);
    saveSvgAsPng(document.getElementById("certification"), `certification-${values.id}.png`, {
      fonts: null,
      scale: 3,
    });
  }, 5000), []) 

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item
        validateStatus={nameError ? "error" : ""}
        help={nameError || ""}
      >
        {getFieldDecorator("name", {
          rules: [{ required: true, message: "请输入姓名！" }]
        })(<Input placeholder="请输入姓名" disabled={hasSubmit}/>)}
      </Form.Item>
      <Form.Item
        validateStatus={phoneError ? "error" : ""}
        help={phoneError || ""}
      >
        {getFieldDecorator("phone", {
          rules: [
            { required: true, message: "请输入电话号码！" },
            {
              pattern: /^1[34578]\d{9}$/,
              message: "手机号码格式不正确"
            }
          ]
        })(<Input placeholder="请输入电话号码" disabled={hasSubmit}/>)}
      </Form.Item>
      <Form.Item
        validateStatus={wechatError ? "error" : ""}
        help={wechatError || ""}
      >
        {getFieldDecorator("wechat", {
          rules: [
            { required: true, message: "请输入微信号！" },
          ]
        })(<Input placeholder="请输入微信号" disabled={hasSubmit}/>)}
      </Form.Item>
      <div className='btn-wrapper'>
        <Button
          disabled={hasErrors(getFieldsError()) || hasSubmit}
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
        <Button onClick={handleCloseModal}>
          取消
        </Button>
        {hasSubmit && certificationValues && (
          <Button type="primary" onClick={downloadCertification}>
            下载证书
          </Button>
        )}
      </div>
      {hasSubmit && certificationValues && (
        <Certification course={course} name={certificationValues.name} id={certificationValues.id} date={certificationValues.date}/>
      )}
    </Form>
  )
}

const ModalForm = Form.create()(FormInner)

const preventDefault = (e) => {
  e.preventDefault()
}

export default function Video({
  course,
  courseKey,
  isMobile,
}) {
  const [ completed, setCompleted ] = useState([false, false])  // 0 watch for 1 hours  1 progress bar to end
  const [ modalVisible, setModalVisible ] = useState(false)

  useEffect(() => {
    let video = document.getElementById('diacVideo')
    video.textTracks[0].mode = 'hidden'

    clearTimeout(timer)
    timer = setTimeout(() => {
      setCompleted(preCompleted => {
        let newCompleted = [...preCompleted]
        newCompleted[0] = true
        return newCompleted
      })
      clearTimeout(timer)
    }, 3600000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if(completed[0] && completed[1]) {
      if(document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
      handleOpenModal()
    }
  }, [completed])

  const handleOpenModal = useCallback(() => {
    document.addEventListener('touchmove', preventDefault,{ passive:false })
    setModalVisible(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    document.removeEventListener('touchmove', preventDefault,{ passive:false })
    setModalVisible(false)
  }, [])

  const finishVideo = useCallback(() => {
    if(!completed[1]) {
      let newCompleted = [...completed]
      newCompleted[1] = true
      setCompleted(newCompleted)
    }
  }, [completed])

  return (
    <div className="container-wrapper">
      <h2 className='video-title'>{course.title}</h2>
      <div className='video-content-wrapper'>
        <div className='video'>
          {((isMobile && !modalVisible) || !isMobile) ? (
            <video
              id="diacVideo"
              preload="metadata"
              x5-playsinline=""
              playsInline={true}
              webkit-playsinline="true"
              x-webkit-airplay="true"
              x5-video-player-type="h5"
              x5-video-player-fullscreen=""
              x5-video-orientation="portraint"
              autoPlay="autoplay"
              controls="controls"
              controlsList="nodownload"
              onEnded={finishVideo}
              disablePictureInPicture
            >
              <track kind="captions" label="试听课" />
              <source type="video/mp4" src={`https://course.xunjingtech.com/bjkw_training/${courseKey}`} />
            </video>
          ) : null}
        </div>
        <div className='video-content'>
          <div className='company-description'>
            <h3 className='title'>迅竞科技，中国高科技人才培养摇篮，为每一个人打开通往未来之路。</h3>
            <p>
              来Offer是迅竞科技旗下的高科技在线教育和职业培训平台。自2013年以来，学员中已有3000余人进入Google、Facebook、今日头条、华为等一线公司，自2016年起，来Offer学员每周可拿到10~28个offer（详见<a target="_blank" href="https://www.laioffer.com/zh/offer-board/">offer榜</a>）
            </p>
          </div>
          <div className='course-description'>
            <p>{course.desc}</p>
            <p>{course.lecturer}</p>
          </div>
          <div className='tip'>
            <span>*</span>&nbsp;&nbsp;
            试听（至少一小时）结束后请填写个人信息，将可以获得试听证书（需使用PC端访问），并将有机会获得由北京市科学技术委员会颁发的人才培养证书。
          </div>
        </div>
      </div>
      <Modal
        visible={modalVisible}
        onCancel={handleCloseModal}
        wrapClassName="form-modal-wrapper"
        footer={null}
        maskClosable={false}
        width="6.4rem"
        centered={true}
      >
        <p className='finish-tip'>您已完成试听，请填写个人信息，获得试听证书。</p>
        <ModalForm courseKey={courseKey} course={course} handleCloseModal={handleCloseModal}/>
      </Modal>
    </div>
  )
}