const HeaderData = {
  menuItems: [
    {
      key: "home",
      value: "产品介绍",
      path: "home"
    }, 
    {
      key: "about",
      value: "关于我们",
      path: "about"
    },
  ]
};

export default HeaderData;
