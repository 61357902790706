import React, { useState, useEffect, useMemo } from "react";
import { Layout, Menu, Popover, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import { HeaderData } from "../../static/content";
import { map, throttle, last } from "lodash";

function Header ({
  theme,
  location: {
    pathname
  },
  isMobile
}) {
  const [ selectedKey, setSelectedKey ] = useState('')
  const [ mobileMenuCollapsed, setMobileMenuCollapsed ] = useState(false)
  const [ scrollX, setScrollX ] = useState(0)
  
  useEffect(() => {
    setSelectedKey(last(pathname.match(/([^\/]+)/g)))
    setMobileMenuCollapsed(false)
  }, [pathname])

  useEffect(() => {
    setMobileMenuCollapsed(false)
  }, [isMobile])

  const { menuItems } = HeaderData

  useEffect(() => {
    document.addEventListener("scroll", onDocumentScroll)
    return () => {
      document.removeEventListener("scroll", onDocumentScroll);
    }
  }, [])
  
  const onDocumentScroll = useMemo(() => throttle(() => {
    setScrollX(window.scrollX)
  }, 100), [])


  const toggleMobileMenu = () => {
    setMobileMenuCollapsed(!mobileMenuCollapsed)
  }

  const renderMenu = (
    <Menu
      mode="horizontal"
      theme={theme}
      className='header-menu'
      selectedKeys={[selectedKey]}
    >
      {map(menuItems, (menuItem, index) => (
        <Menu.Item key={menuItem.key}>
          <Link to={`/${menuItem.path}`}>{menuItem.value}</Link>
          {index < menuItems.length - 1 ? (
            <span className='separator'>/</span>
          ): null}
        </Menu.Item>
      ))}
    </Menu>
  )

  const renderMobileMenu = (
    <Menu
      mode="vertical"
      theme={theme}
      selectedKeys={[selectedKey]}
    >
      {map(menuItems, (menuItem, index) => (
        <Menu.Item key={menuItem.key}>
          <Link to={`/${menuItem.path}`}>{menuItem.value}</Link>
          {index < menuItems.length - 1 ? (
            <span className='separator'>/</span>
          ): null}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Layout.Header className={`header-wrapper ${theme}`} style={{left: `-${scrollX}px`}}>
      <div className='header'>
        <Link to="/home" className='logo img-wrapper' />
        {renderMenu}
        <Popover
          className="mobile-header-menu"
          trigger="click"
          content={renderMobileMenu}
          placement="bottomRight"
          visible={mobileMenuCollapsed}
          onVisibleChange={toggleMobileMenu}
          title={(
            <React.Fragment>
              <div className='logo img-wrapper' />
              <Icon type="close" onClick={toggleMobileMenu}/>
            </React.Fragment>
          )}
          getPopupContainer={() => document.getElementsByClassName('mobile-header-menu')[0]}
        >
          <Icon type="menu" />
        </Popover>
      </div>
    </Layout.Header>
  )
}

export default withRouter(Header)