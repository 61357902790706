import React from "react";
import { map } from "lodash";
import { Carousel } from 'antd'

export default function Programming({
  tour: {
    title,
    desc,
    features
  },
  showBackground,
  isMobile
}){
  const settings = {
    autoplay: true,
    dots: false,
    className: "photo-carousel",
    centerMode: true,
    infinite: true,
    pauseOnHover: false,
    centerPadding: isMobile ? '40px' : "-10px",
    slidesToShow: isMobile ? 1 : 3,
    speed: 600
  };

  return (
    <div className="newskyline-tour-wrapper container-wrapper">
      <div className={`parallax-bg newskyline ${showBackground ? '' : 'hidden'}`}/>
      <div className="newskyline-tour container ">
        <h1 className='title'>{title}</h1>
        <div className='desc'>{desc}</div>
        <div className='features-wrapper'>
          <Carousel {...settings}>
            {map(features, (feature, index) => (
              <div className='feature-wrapper' key={feature.key}>
                <div className="image-wrapper" >
                  <img src={require(`../../../static/images/graphs/newskyline-tour-${feature.key}.jpg`)} alt='tour'/>
                </div>
                <div className='title'>{feature.title}</div>
              </div>
            ))}
            {map(features, (feature, index) => (
              <div className='feature-wrapper' key={feature.key}>
                <div className="image-wrapper" >
                  <img src={require(`../../../static/images/graphs/newskyline-tour-${feature.key}.jpg`)} alt='tour'/>
                </div>
                <div className='title'>{feature.title}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
