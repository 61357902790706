import React, { useState, useEffect } from "react";
import { map } from "lodash";

import { Banner } from '../../index'
export default function Introductions({
  introductions,
  services,
  showBackground,
  arrived,
  isMobile
}){
  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if(arrived) {
      setShow(true)

      // const nodes = document.querySelectorAll('.laioffer-introductions-wrapper .introduction-wrapper')
      // for(let i = 0; i < nodes.length; i++) {
      //   animateCSS(nodes[i], 'filpInX', 1)
      // }
    }
  }, [arrived])

  return (
    <div className="laioffer-introductions-wrapper container-wrapper">
      <div className={`parallax-bg laioffer ${showBackground ? '' : 'hidden'}`}/>
      <Banner
        logo={introductions.key}
        title={introductions.title}
        subTitle={introductions.subTitle}
        action={introductions.action}
      >
        <div className='introductions-wrapper'> 
          {map(introductions.introductionList, (introduction, index) => (
            <div className='introduction-wrapper' key={index}>
              <h3 className='title'>{introduction.title}</h3>
              <div className='desc'>{introduction.desc}</div>
            </div>
          ))}
        </div>
      </Banner>
      <div className="laioffer-services-wrapper container-wrapper no-full-screen">
        <div className="laioffer-services container horizontal">
          <h3 className='title'>{services.title}<span className='separator'>/</span></h3>
          <div className='services-wrapper'>
            {isMobile || show ? map(services.serviceList, (service, index) => (
              <div 
                className={`service-wrapper ${!isMobile && show ? 'animated bounceInRight': ''}`}
                key={service.key}
              >
                <div className={`icon icon${service.key}`}/>
                <p className='title'>{service.title}</p>
              </div>
            )) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
