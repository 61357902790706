import React, { useState, useRef, useEffect } from "react";

export default function Banner({
  title, 
  subTitle,
  arrived,
  isMobile
}){
  const videoRef = useRef()
  const [ muted, setMuted ] = useState(true)
  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if(arrived) {
      setShow(true)
    }
  }, [arrived])

  const toggleMute = () => {
    videoRef.current.muted = !muted
    setMuted(!muted)
  }

  return (
    <div className="home-banner-wrapper container-wrapper">
      {!isMobile ? (
        <div className='video-wrapper'>
          <video
            ref={videoRef}
            className="video"
            preload="metadata"
            autoPlay
            loop
            muted = {muted}
            playsInline
          >
            <track kind="captions" label="xunjing" />
            <source type="video/mp4" src="https://public.xunjingtech.com/video/xunjingtech-20190716.mp4" />
          </video>
          <button
            type="button"
            className={`btn-video-mute ${muted ? 'mute' : ''}`}
            onClick={toggleMute}
          />
        </div>
      ) : null}
      <div className="home-banner container ">
        <div className={`title-wrapper ${!isMobile && show ? 'animated zoomIn delay-1s': ''}`}>
          <h1 className='title dark'>{title}</h1>
          <h1 className='title dark'>{subTitle}</h1>
        </div>
      </div>
    </div>
  );
}
