import React, { useState } from 'react';
import { map, reduce } from 'lodash';
import { Row, Col, Card, Modal, Icon } from 'antd';

function Instructors({
  instructors
}) {
  const [ modalVisible, setModalVisible ] = useState(false)
  const [ modalData, setModalData ] = useState(null)

  const onShowModal = e => {
    const instructorIndex = parseInt(e.currentTarget.getAttribute('data-index'))
    setModalVisible(true)
    setModalData({
      ...instructors[instructorIndex],
      index: instructorIndex
    })
  };

  const onCancel = () => {
    setModalVisible(false)
    setModalData(null)
  };

  const renderLists = (list, listId) => {
    return map(list, (item, i) => <li key={`${listId}-${i}`}>{item}</li>);
  }

  const renderCourses = (courses, instructorKey, uid) => {
    return reduce(
      map(courses, (course, i) => (
        <div
          className="stats-label"
          key={`index.instructors.${uid}.${instructorKey}.${i}`}>
          {course}
        </div>
      )),
      (prev, curr) => [
        prev,
        <span
          className="stats-separator"
          key={`index.instructors.course.${prev}`}>
          {' '}
          |{' '}
        </span>,
        curr
      ]
    );
  }

  const renderAcademicBackground = (academicBackground) => (
    <section>
      <h4>教育背景</h4>
      <ul>{renderLists(academicBackground, 'background')}</ul>
    </section>
  )

  const renderExperiences = (experience) => (
    <section>
      <h4>职业亮点</h4>
      <ul>{renderLists(experience, 'experience')}</ul>
    </section>
  )

  const renderSpecialization = (specialization) => (
    <section>
      <h4>专攻领域</h4>
      <ul>{renderLists(specialization, 'specialization')}</ul>
    </section>
  )

  const renderInstructorCard = ({
    key,
    name,
    courses,
    description,
    specialization,
    experience
  }, index) => (
    <Col className="gutter-row" xs={24} sm={12} lg={8} key={`index.instructors.${key}`}>
      <Card
        hoverable
        className="gutter-box"
        bodyStyle={{ padding: 0 }}
        bordered={false}
        data-index={index}
        onClick={onShowModal}>
        <div className="card-head">
          <img
            className={`profile-${index} profile-image`}
            src={require(`../../static/images/graphs/diac/${key}.jpg`)}
            alt={name}
          />
          <h3>{name}</h3>
          <div className="stats-container">
            {renderCourses(courses, key, 'card')}
          </div>
        </div>
        <div className="card-content">
          <p className="description">{description}</p>
          {renderSpecialization(specialization)}
          {renderExperiences(experience)}
        </div>
        <button className="btn-link" type="button">
          更多资历 &nbsp;
          <Icon type="arrow-right" />
        </button>
      </Card>
    </Col>
  )

  return (
    <div className="container-wrapper">
      <h2 className="main-title">
        名师阵容
        <div className="dash" />
      </h2>
      <Row gutter={42} type="flex">
        {map(instructors, (instructor, index) => renderInstructorCard(instructor, index))}
      </Row>
      {modalData && (
        <Modal
          className="instructor-modal"
          title={[
            <img
              key="index.instructors.modal.profileImage"
              className={`profile-${modalData.index} profile-image`}
              src={require(`../../static/images/graphs/diac/${modalData.key}.jpg`)}
              alt={modalData.name}
            />,
            <h3 key="index.instructors.modal.profileName">
              {modalData.name}
            </h3>,
            <div
              key="index.instructors.modal.profileStats"
              className="stats-container">
              {renderCourses(
                modalData.courses,
                modalData.key,
                'modal'
              )}
            </div>
          ]}
          centered={true}
          footer={null}
          width='6.4rem'
          visible={modalVisible}
          onCancel={onCancel}>
          <p>{modalData.description}</p>
          {renderSpecialization(modalData.specialization)}
          {renderExperiences(modalData.experience)}
          {renderAcademicBackground(modalData.academicBackground)}
        </Modal>
      )}
    </div>
  )
}

export default Instructors;
