import React, { useState, useEffect } from "react";
import { map } from "lodash";
import { Button } from 'antd'

export default function Programming({
  course: {
    title,
    categories,
    features
  },
  arrived,
  isMobile
}){

  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if(arrived) {
      setShow(true)
    }
  }, [arrived])

  return (
    <div className="newskyline-course-wrapper container-wrapper">
      <div className="newskyline-course container horizontal">
        {isMobile || show ? (
          <React.Fragment>
            <div className={`features-wrapper ${!isMobile && show ? 'animated fadeInLeftBig slow-1': ''}`}>
              <h1 className='title'>{title}</h1>
              {map(features, (feature, index) => (
                <div className='feature-wrapper' key={index}>
                  <div className='icon-wrapper'>
                    <div className={`icon icon${feature.key}`}/>
                  </div>
                  
                  <div className='title'>{index + 1}. {feature.title}</div>
                </div>
              ))}
            </div>
            <div className='categories-wrapper'>
              {map(categories, (category, index) => (
                <div key={category.key} className={`category-wrapper ${!isMobile && show ? 'animated flipInY slow-2 delay-1s': ''}`}>
                  <div className={`banner-wrapper ${category.key}`}>
                    <div className='img-wrapper'>
                      <img src={require(`../../../static/images/graphs/newskyline-course-${category.key}.jpg`)}/>
                    </div>
                    <div className='title-wrapper'>
                      <div className='title-en'>{category.titleEn}</div>
                      <div className='title'>{category.title}</div>
                    </div>
                  </div>
                  <div className='content-wrapper'>
                    <div className='desc'>{category.desc}</div>
                    <div className='action-wrapper'>
                      <Button size='large'>
                        <a href={category.action.to} target='_blank'>
                          {category.action.title}
                        </a>
                      </Button>
                    </div>
                  </div>
                  
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : null}
        
      </div>
    </div>
  );
}
